<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab title="Details">
        <editable-card
          title="Personal Details"
          :read-view="userComponents.read"
          :edit-view="userComponents.edit"
          :child-props="userProps"
          :isAdmin="true"
          :show-edit="false"
          class="mb-4 mt-3"
        />
        <b-button variant="outline-primary" :href="url">
          Reset Password
        </b-button>
      </b-tab>
      <b-tab title="Files">
        <file-list title="Personal Files"></file-list>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import EditableCard from "../interface/EditableCard";
import UserView from "./UserView";
import UserEdit from "./UserEdit";
import FileList from "@/components/file/FileList.vue";

export default {
  name: "UserDetails",
  components: {
    FileList,
    EditableCard,
  },
  computed: {
    ...mapState("auth", {user: state => state.currentUser}),
    ...mapState("configStore", {sfApplicantUrl: state => state.sfApplicantUrl}),
    userProps() {
      return {
        user: this.user,
      };
    },
    url() {
      return this.sfApplicantUrl + "/applicant/edit";
    },
  },
  data() {
    return {
      userComponents: {
        read: UserView,
        edit: UserEdit,
      },
    };
  },
  methods: {
    ...mapActions("configStore", ["fetchSfApplicantUrl"]),
  },
  mounted() {
    this.fetchSfApplicantUrl();
  },
};
</script>

<style scoped>
.display_page_title {
  display: inline-block;
}
</style>
